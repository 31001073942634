import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import Contact from "./pages/contact-us";
import About from "./pages/about";
import Courses from "./pages/courses";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/contact-us",
    element: <Contact />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/course",
    element: <Courses />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
