import { Box, Typography } from "@mui/material";
import React from "react";
import greenCap from "../../assets/home/GraduationCap.svg";
import person from "../../assets/home/enroll.svg";
import swoosh from "../../assets/home/swoosh.svg";
import color from "../../config/color";

export default function Enroll({ upsm }) {
  return (
    <Box
      sx={{
        bgcolor: "#F1FCFF",
        p: 4,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexDirection: upsm ? "row" : "column",
      }}
    >
      <img src={person} alt="" style={{ width: upsm ? "30%" : "100%" }} />
      <Box>
        <Typography
          variant="h3"
          sx={{ width: "100%", textAlign: "center", fontWeight: "bold" }}
        >
          Enroll in{" "}
          <span style={{ color: color.primary, position: "relative" }}>
            CPC/CCS
            <img
              src={swoosh}
              alt=""
              style={{ position: "absolute", bottom: -10, left: 0 }}
            />
          </span>{" "}
          Certification
        </Typography>
        <Box sx={{ display: "flex", mt: 4, justifyContent: "center" }}>
          <img
            src={greenCap}
            alt=""
            style={{
              backgroundColor: color.primary50,
              padding: 2,
              borderRadius: 10,
            }}
          />
          <Box sx={{ ml: 2 }}>
            <Typography variant="h5">100% Pass Results</Typography>
            <Typography variant="body2" color={color.secondary}>
              We Guarentee 100% pass results
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", mt: 4, justifyContent: "center" }}>
          <img
            src={greenCap}
            alt=""
            style={{
              backgroundColor: color.primary50,
              padding: 2,
              borderRadius: 10,
            }}
          />
          <Box sx={{ ml: 2 }}>
            <Typography variant="h5">Free Demo</Typography>
            <Typography variant="body2" color={color.secondary}>
              Free demo available for all courses
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", mt: 4, justifyContent: "center" }}>
          <img
            src={greenCap}
            alt=""
            style={{
              backgroundColor: color.primary50,
              padding: 2,
              borderRadius: 10,
            }}
          />
          <Box sx={{ ml: 2 }}>
            <Typography variant="h5">100% Job Assistance</Typography>
            <Typography variant="body2" color={color.secondary}>
              100+ placement assistance and more <br /> than 50 students were
              placed in MNC’s.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
