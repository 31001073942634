import React from "react";
import Header from "../home/Header";
import { useMediaQuery, useTheme } from "@mui/material";
import Form from "./Form";
import Footer from "../home/Footer";

export default function Contact() {
  const theme = useTheme();
  const upsm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div>
      <Header upsm={upsm} />
      <Form upsm={upsm} />
      <Footer upsm={upsm} />
    </div>
  );
}
