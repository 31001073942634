import { Box, Card, Container, Typography } from "@mui/material";
import Carousel from "react-elastic-carousel";
import React from "react";
import comma from "../../assets/home/comaa.svg";
import swoosh from "../../assets/home/swoosh.svg";
import color from "../../config/color";
import isha from "../../assets/feedback/isha.jpeg";
import ishaan from "../../assets/feedback/Ishaan.jpeg";
import vanshika from "../../assets/feedback/vanshika.jpeg";

export default function Feedback({ upsm }) {
  return (
    <Container sx={{ p: 4 }}>
      <Typography variant="h4">
        Student{" "}
        <span style={{ color: color.primary, position: "relative" }}>
          Feedback
          <img
            src={swoosh}
            alt=""
            style={{ position: "absolute", bottom: -10, left: 0 }}
          />
        </span>
      </Typography>
      {/* <Typography variant="body2" color={color.secondary} sx={{ my: 2 }}>
        Various versions have evolved over the years, sometimes by accident,
      </Typography> */}
      <Carousel
        itemsToShow={upsm ? 4 : 1}
        showArrows={false}
        pagination={false}
      >
        {[
          {
            name: "Isha",
            content:
              "It was a great experience for me.They provide perfect guidance with good material .I liked the way of teaching very easy to understand and also the friendly atmosphere.I'm glad that I joined this coaching got to know many things. I will surely recommend this institute if u want to clear your CPC exam in first attempt.",
            image: isha,
          },
          {
            name: "Vanshika",
            content:
              "Thanku so much ma'am for making learning so easy fun and excited. Your encouragement and support have given me the confidence and motivation to crack the exam so easily and in first attempt. Your hard work and commitment make a significant difference in my life. Your positive energy has created a learning environment where I felt comfortable to ask questions. You have a great ability to make complex concept simple and easier which make a best impact on my academic studies. Thnku so much ma'am for your support and motivation ❤😇",
            image: vanshika,
          },
          {
            name: "Ishaan",
            content:
              "It was great learning and preparing at Modan for the Medical coding. It benefited in getting the CPC certification and job.",
            image: ishaan,
          },
        ].map((x) => (
          <Card
            sx={{
              maxWidth: 345,
              p: 2,
              m: 1,
              position: "relative",
              // border: "2px solid red",
              height: "40vh",
            }}
          >
            <img
              src={comma}
              alt=""
              style={{ position: "absolute", right: 25 }}
            />
            <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
              <img
                // src="https://images.pexels.com/photos/1239291/pexels-photo-1239291.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                src={x.image}
                alt=""
                style={{ borderRadius: 500, width: 48, height: 48 }}
              />
              <Typography variant="body1" sx={{ ml: 1 }}>
                {x.name}
              </Typography>
            </Box>
            <Typography variant="caption" color={color.secondary}>
              {x.content}
            </Typography>
          </Card>
        ))}
      </Carousel>
    </Container>
  );
}
