import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import color from "../../config/color";
import swoosh from "../../assets/home/swoosh.svg";
import formImg from "../../assets/contact/map.svg";
import emailjs from "@emailjs/browser";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
// import GoogleMapReact from "google-map-react";

// const subject = "Contact Us";
export default function Form({ upsm }) {
  // const center = {
  //   lat: 10.99835602,
  //   lng: 77.01502627,
  // };
  const form = useRef();
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumber] = useState(0);
  const [reference, setreference] = useState("");
  const [showSuccess, setshowSuccess] = useState(false);

  // const body = `Name: ${name}, Email: ${email}, Number: ${number}, Reference: ${reference}`;
  const sendEmail = async (e) => {
    console.log("sending email");
    const service_id = process.env.REACT_APP_SERVICE_ID;
    const template_id = process.env.REACT_APP_TEMPLATE_ID;
    const user_id = process.env.REACT_APP_USER_ID;

    e.preventDefault();
    const response = await emailjs.sendForm(
      service_id,
      template_id,
      form.current,
      user_id
    );
    console.log({ response });
    setshowSuccess(true);
  };

  return (
    <Container sx={{ minHeight: "80vh" }}>
      <Box
        sx={{
          flex: 2,
          p: upsm ? 6 : 0,
          display: "flex",
          alignItems: "center",
          flexDirection: upsm ? "row" : "column",
        }}
      >
        <Box>
          {showSuccess && (
            <Alert
              icon={<CheckIcon fontSize="inherit" />}
              severity="success"
              onClose={() => setshowSuccess(false)}
            >
              Here is a gentle confirmation that your action was successful.
            </Alert>
          )}
          <Typography variant="h4" sx={{ marginTop: "2%" }}>
            Contact{" "}
            <span style={{ color: color.primary, position: "relative" }}>
              Us
              <img
                src={swoosh}
                alt=""
                style={{ position: "absolute", bottom: -10, left: 0 }}
              />
            </span>
          </Typography>
          <Typography variant="body2" color={color.secondary} sx={{ my: 2 }}>
            Enter your details below to get in touch with us. We will get back
            to you as soon as possible.
          </Typography>
          <form ref={form} onSubmit={sendEmail}>
            <TextField
              id="outlined-basic"
              label="Name"
              required
              variant="outlined"
              fullWidth
              onChange={(e) => setname(e.target.value)}
              name="user_name"
            />
            <TextField
              id="outlined-basic"
              label="Email"
              type="email"
              name="user_email"
              variant="outlined"
              fullWidth
              sx={{ my: 2 }}
              onChange={(e) => setemail(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Phone Number"
              name="user_number"
              required
              variant="outlined"
              fullWidth
              onChange={(e) => setnumber(e.target.value)}
            />
            <FormControl fullWidth sx={{ marginTop: 2 }}>
              <InputLabel>How did you find us?</InputLabel>
              <Select
                label="How did you find us?"
                onChange={(e) => setreference(e.target.value)}
                name="user_reference"
              >
                <MenuItem value="linkedin">Linkedin</MenuItem>
                <MenuItem value="instagram">Instagram</MenuItem>
                <MenuItem value="facebook">Facebook</MenuItem>
                <MenuItem value="google">Google</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              fullWidth
              sx={{
                bgcolor: color.primary,
                textTransform: "capitalize",
                my: 2,
              }}
              // onClick={sendEmail}
              type="submit"
            >
              send
            </Button>
          </form>
        </Box>
        <Box
          sx={{
            ml: upsm ? 8 : 0,
            width: "100%",
            // border: "2px solid red",
            // height: "70vh",
          }}
        >
          <img src={formImg} alt="" style={{ height: "70vh", width: "100%" }} />
          {/* <GoogleMapReact
            defaultZoom={11}
            defaultCenter={center}
          ></GoogleMapReact> */}
        </Box>
      </Box>
    </Container>
  );
}
