import { Box, Button, Container, TextField, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/logo.svg";
import color from "../../config/color";
import { useNavigate } from "react-router-dom";

export default function Footer({ upsm }) {
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: upsm ? "row" : "column",
      }}
    >
      <Box sx={{ flex: 1, width: "100%" }}>
        <img src={logo} alt="" />
        <Typography variant="body1" fontWeight="bold" p={1}>
          Contact Us
        </Typography>
        <Typography variant="body2" color={color.secondary} p={1}>
          Call : +91 8929994914, +918295326069
        </Typography>
        <Typography variant="body2" p={1} color={color.secondary}>
          1/45 A,lalita park, laxmi Nagar,Shakarpur Baramad, Delhi, India 110092
        </Typography>
        <Typography variant="body2" p={1} color={color.secondary}>
          Email: info@modamsolutions.in
        </Typography>
      </Box>
      <Box sx={{ flex: 1, width: "100%" }}>
        <Typography variant="body1" p={1} fontWeight="bold">
          Explore
        </Typography>
        <Typography
          variant="body2"
          color={color.secondary}
          p={1}
          onClick={() => {
            navigate("/");
          }}
        >
          Home
        </Typography>
        <Typography
          variant="body2"
          p={1}
          color={color.secondary}
          onClick={() => {
            navigate("/about");
          }}
        >
          About
        </Typography>
        {/* <Typography variant="body2" p={1} color={color.secondary}>
          Course
        </Typography> */}
        {/* <Typography variant="body2" p={1} color={color.secondary}>
          Partners
        </Typography> */}
        <Typography
          variant="body2"
          p={1}
          color={color.secondary}
          onClick={() => {
            navigate("/contact-us");
          }}
        >
          Contact Us
        </Typography>
      </Box>
      <Box sx={{ flex: 1, width: "100%" }}>
        <Typography variant="body1" p={1} fontWeight="bold">
          Courses
        </Typography>
        <Typography variant="body2" color={color.secondary} p={1}>
          CPC
        </Typography>
        <Typography variant="body2" p={1} color={color.secondary}>
          CCS
        </Typography>
        <Typography variant="body2" p={1} color={color.secondary}>
          Surgical Terms
        </Typography>
        <Typography variant="body2" p={1} color={color.secondary}>
          Medical Coding
        </Typography>
      </Box>
      {/* <Box sx={{ flex: 1 }}>
        <Typography variant="body1" p={1} fontWeight="bold">
          Subscribe
        </Typography>
        <Typography variant="body2" color={color.secondary} p={1}>
          Lorem Ipsum has been them an industry printer took a galley make book.
        </Typography>
        <TextField fullWidth label="Email Here" variant="filled" />
        <Button
          variant="contained"
          sx={{
            bgcolor: color.primary,
            textTransform: "capitalize",
            my: 2,
          }}
        >
          Subscribe Now
        </Button>
      </Box> */}
    </Container>
  );
}
