import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Header from "../home/Header";
import Footer from "../home/Footer";
import AllCourse from "./AllCourse";

export default function Courses() {
  const theme = useTheme();
  const upsm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div>
      <Header upsm={upsm} />
      <AllCourse upsm={upsm} />
      <Footer upsm={upsm} />
    </div>
  );
}
