import React from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import color from "../../config/color";
import swoosh from "../../assets/home/swoosh.svg";
import data from "../../config/courses.json";
// import { Carousel } from "@trendyol-js/react-carousel";
import Carousel from "react-elastic-carousel";

export default function AllCourse({ upsm }) {
  return (
    <Box>
      <Typography variant="h4" sx={{ width: "100%", textAlign: "center" }}>
        Popular{" "}
        <span style={{ color: color.primary, position: "relative" }}>
          Courses
          <img
            src={swoosh}
            alt=""
            style={{ position: "absolute", bottom: -10, left: 0 }}
          />
        </span>
      </Typography>
      <Carousel
        itemsToShow={upsm ? 4 : 1}
        showArrows={false}
        pagination={false}
      >
        {/* <Box
        sx={{
          display: "flex",
          overflow: "auto",
          scrollbarWidth: "thin",
        }}
      > */}
        {data.map((item) => (
          <Card sx={{ m: 2, width: "100%", height: "45vh" }}>
            <CardMedia
              component="img"
              sx={{ height: 140 }}
              src="https://cdn.pixabay.com/photo/2023/09/22/07/02/red-8268266_1280.jpg"
            />
            <CardContent
              sx={
                {
                  // display: "flex",
                  // flexDirection: "row",
                  // alignItems: "flex-end",
                  // justifyContent: "flex-end",
                  // border: "2px solid red",
                  // p: 0,
                }
              }
            >
              {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {item.duration}
                </Typography> */}
              {/* <Typography variant="h5" component="div">
                  {item.instructor}
                </Typography> */}
              <Typography variant="h5">{item.courseName}</Typography>
            </CardContent>
            {/* <CardActions>
                <Button size="small">$ 500</Button>
              </CardActions> */}
          </Card>
        ))}
        {/* </Box> */}
      </Carousel>
    </Box>
  );
}
