import { Box, Container, Typography } from "@mui/material";
import React from "react";
import color from "../../config/color";
import swoosh from "../../assets/home/swoosh.svg";
import form from "../../assets/about/about-img.svg";

export default function About({ upsm }) {
  return (
    <Container sx={{ my: 4 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: upsm ? "row" : "column",
        }}
      >
        <Box sx={{ p: 6, width: "100%" }}>
          <Typography variant="h4">
            About{" "}
            <span style={{ color: color.primary, position: "relative" }}>
              Us
              <img
                src={swoosh}
                alt=""
                style={{ position: "absolute", bottom: -10, left: 0 }}
              />
            </span>
          </Typography>
          <Typography variant="body2" color={color.secondary} sx={{ my: 2 }}>
            Modam is India's most trusted and Internationally affiliated Medical
            Coding training and placement provider. We train/create innovative
            and engaging certificate programs and diplomas leading to
            occupation, expansion, and betterment in the most significant and
            fastest-growing fields within the healthcare information industry.
          </Typography>
          <Typography variant="body2" color={color.secondary} sx={{ my: 2 }}>
            We are 100% intent on international training standards and provide
            the best infrastructure with well-equipped classrooms and
            technology-oriented facilities for Medical coding education.
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <img src={form} alt="" style={{ width: "100%" }} />
        </Box>
      </Box>
    </Container>
  );
}
