const color = {
  primary: "#53B4D0",
  secondary: "#6D737A",
  neutral: "#FB92A3",
  primary50: "#E9F8F3",
  primary500: "#20B486",
  gray600: "#52565C",
  secondary50: "#FFFAF5",
};
export default color;
