import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { Box, Button, Card, Container, Typography } from "@mui/material";
import React from "react";
import hero from "../../assets/home/hero.png";
import color from "../../config/color";
import { useNavigate } from "react-router-dom";

export default function Hero({ upsm }) {
  const navigate = useNavigate();
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: upsm ? "row" : "column",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <Box sx={{ maxWidth: 420, flex: 1 }}>
          <Typography variant="body1" sx={{ color: color.primary }}>
            START TO SUCCESS
          </Typography>
          <Typography variant="h4">
            Are you <span style={{ color: color.primary }}>ready</span>? <br />{" "}
            To Become a <span style={{ color: color.primary }}>Medical</span>{" "}
            Coder
          </Typography>
          <Typography variant="body2" color={color.secondary}>
            Enrollment in CPC/CCS Certification batches along with speciality
            training in surgery and ED/E&M
          </Typography>
          <Button
            variant="contained"
            sx={{
              bgcolor: color.primary,
              textTransform: "capitalize",
              my: 2,
            }}
            onClick={() => {
              navigate("/contact-us");
            }}
          >
            Register Now
          </Button>
        </Box>
        <Box sx={{ flex: 1, position: "relative" }}>
          <img src={hero} alt="" style={{ width: "100%" }} />
          <Card
            sx={{
              display: "flex",
              p: 1,
              position: "absolute",
              bottom: upsm ? "30%" : "50%",
              right: "0",
            }}
          >
            <Box sx={{ bgcolor: color.primary50, borderRadius: 1, mr: 1 }}>
              <StarRateRoundedIcon sx={{ color: color.primary500 }} />
            </Box>
            <Typography sx={{ color: color.gray600 }}>5.0 Rating</Typography>
          </Card>
        </Box>
      </Box>
    </Container>
  );
}
