import { Box, Typography } from "@mui/material";
import React from "react";
import greenCap from "../../assets/home/GraduationCap.svg";
import Student from "../../assets/home/GraduationCap_Green.svg";
import User from "../../assets/home/UsersThree.svg";
import Video from "../../assets/home/VideoCamera.png";
import Person from "../../assets/home/acheive.svg";
import swoosh from "../../assets/home/swoosh.svg";
import color from "../../config/color";

export default function OurAcheivement({ upsm }) {
  return (
    <Box
      sx={{
        p: 4,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexDirection: upsm ? "row" : "column",
      }}
    >
      <Box>
        <Typography
          variant="h4"
          sx={{ width: "100%", textAlign: "center", fontSize: "3rem" }}
        >
          Our{" "}
          <span style={{ color: color.primary, position: "relative" }}>
            Achievement
            <img
              src={swoosh}
              alt=""
              style={{ position: "absolute", bottom: -10, left: 0 }}
            />
          </span>
        </Typography>

        {/* <Typography
          variant="body2"
          color={color.secondary}
          sx={{ textAlign: "center", mt: 2 }}
        >
          Various versions have evolved over the years, sometimes by accident,
        </Typography> */}
        {/* item box 1 */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
          <Box sx={{ display: "flex" }}>
            <img
              src={greenCap}
              alt=""
              style={{
                backgroundColor: color.primary50,
                padding: 2,
                borderRadius: 10,
              }}
            />
            <Box sx={{ ml: 2 }}>
              <Typography variant="h5">300</Typography>
              <Typography variant="body2" color={color.secondary}>
                Instructor
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <img
              src={Video}
              alt=""
              style={{
                backgroundColor: color.secondary50,
                padding: 2,
                borderRadius: 10,
              }}
            />
            <Box sx={{ ml: 2 }}>
              <Typography variant="h5">1000+</Typography>
              <Typography variant="body2" color={color.secondary}>
                Video
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* item box 2 */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
          <Box sx={{ display: "flex" }}>
            <img
              src={Student}
              alt=""
              style={{
                backgroundColor: "#FFEEF0",
                padding: 2,
                borderRadius: 10,
              }}
            />
            <Box sx={{ ml: 2 }}>
              <Typography variant="h5">500+</Typography>
              <Typography variant="body2" color={color.secondary}>
                Students
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <img
              src={User}
              alt=""
              style={{
                backgroundColor: "#F0F7FF",
                padding: 2,
                borderRadius: 10,
              }}
            />
            <Box sx={{ ml: 2 }}>
              <Typography variant="h5">1000+</Typography>
              <Typography variant="body2" color={color.secondary}>
                Users
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
        <img src={Person} alt="" />
      </Box>
    </Box>
  );
}
