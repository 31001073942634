import {
  Box,
  Button,
  Container,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import logo from "../../assets/logo.svg";
import color from "../../config/color";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Link } from "react-router-dom";

const drawerWidth = 240;

export default function Header({ upsm }) {
  const [shoeMenu, setshoeMenu] = useState(false);

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: upsm ? "row" : "column",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <img src={logo} alt="" />
        {!upsm && <MenuOutlinedIcon onClick={() => setshoeMenu(!shoeMenu)} />}
      </Box>
      {upsm ? (
        <>
          <Box
            display="flex"
            alignItems={"center"}
            sx={{
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Link to="/" style={LINK_STYLE}>
              <Typography
                variant="body1"
                sx={{ color: color.secondary, fontWeight: "bold" }}
              >
                Home
              </Typography>
            </Link>
            <Link to="/about" style={LINK_STYLE}>
              <Typography
                variant="body1"
                sx={{ color: color.secondary, ml: 2, fontWeight: "bold" }}
              >
                About Us
              </Typography>
            </Link>
            <Link to="/course" style={LINK_STYLE}>
              <Typography
                variant="body1"
                sx={{ color: color.secondary, mx: 2, fontWeight: "bold" }}
              >
                Courses
              </Typography>
            </Link>
            {/* <Typography variant="body1" sx={{ color: color.secondary, mx: 2 }}>
              Partners
            </Typography> */}
            <Link to="/contact-us" style={LINK_STYLE}>
              <Typography
                variant="body1"
                sx={{ color: color.secondary, fontWeight: "bold" }}
              >
                Contact Us
              </Typography>
            </Link>
          </Box>
          <Box sx={{ width: "100%" }} />
          {/* <Box display={"flex"} alignItems="center">
            <Box display="flex" alignItems="center">
              <LockOutlinedIcon sx={{ color: color.secondary }} />
              <Typography variant="body1" sx={{ color: color.secondary }}>
                Login
              </Typography>
            </Box>
            <Button
              variant="contained"
              sx={{
                bgcolor: color.primary,
                textTransform: "capitalize",
                my: 2,
                ml: 2,
              }}
            >
              Register
            </Button>
          </Box> */}
        </>
      ) : null}

      <Drawer open={shoeMenu} anchor="left" sx={{ width: drawerWidth }}>
        <List sx={{ width: "100%" }}>
          {[
            { name: "Home", to: "/" },
            { name: "About Us", to: "/about" },
            { name: "Courses", to: "/course" },
            // { name: "Partners", to: "" },
            { name: "Contact Us", to: "/contact-us" },
          ].map((text, index) => (
            <Link to={text.to} style={LINK_STYLE}>
              <ListItem
                key={text.name}
                sx={{ width: drawerWidth }}
                onClick={() => setshoeMenu(!shoeMenu)}
              >
                <ListItemText primary={text.name} />
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
    </Container>
  );
}

const LINK_STYLE = { textDecoration: "none", color: color.secondary };
