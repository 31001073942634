import React from "react";
import Hero from "./Hero";
import MostPopularCourses from "./MostPopularCourses";
// import BestInstructor from "./BestInstructor";
import OurAcheivement from "./OurAcheivement";
import Enroll from "./Enroll";
import Feedback from "./Feedback";
import Medicalcode from "./Medicalcode";
import { useMediaQuery, useTheme } from "@mui/material";
import Footer from "./Footer";
import Header from "./Header";

export default function Home() {
  const theme = useTheme();
  const upsm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div>
      <Header upsm={upsm} />
      <Hero upsm={upsm} />
      <MostPopularCourses upsm={upsm} />
      {/* <BestInstructor upsm={upsm} /> */}
      <Feedback upsm={upsm} />
      <Enroll upsm={upsm} />
      <OurAcheivement upsm={upsm} />
      <Medicalcode />
      <Footer upsm={upsm} />
    </div>
  );
}
