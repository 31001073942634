import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import medical from "../../assets/home/medicalTraining.svg";
import swoosh from "../../assets/home/swoosh.svg";
import color from "../../config/color";
import { useNavigate } from "react-router-dom";

export default function Medicalcode() {
  const theme = useTheme();
  const upsm = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        bgcolor: "#F1FCFF",
        p: 4,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexDirection: upsm ? "row" : "column",
      }}
    >
      <img src={medical} alt="" style={{ width: "100%" }} />
      <Box>
        <Typography variant="h3" sx={{ width: "100%" }}>
          Medical Coding{" "}
          <span style={{ color: color.primary, position: "relative" }}>
            Training
            <img
              src={swoosh}
              alt=""
              style={{ position: "absolute", bottom: -10, left: 0 }}
            />
          </span>
        </Typography>
        <Typography variant="body2" color={color.secondary} sx={{ mt: 4 }}>
          The Modam solution is an Educational institute that provide US
          healthcare training to students from various disciplines in Delhi-NCR.
          Our aim to provide help to those who want to make their career in
          healthcare industry. After the training you can choose to work in the
          field of medical coding and billing and can work as a freelancer or
          employee.{" "}
        </Typography>
        <Button
          variant="contained"
          sx={{
            bgcolor: color.primary,
            textTransform: "capitalize",
            my: 2,
          }}
          onClick={() => {
            navigate("/contact-us");
          }}
        >
          Contact Now
        </Button>
      </Box>
    </Box>
  );
}
