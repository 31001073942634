import { Box, Container, Typography } from "@mui/material";
import React from "react";
import color from "../../config/color";
import swoosh from "../../assets/home/swoosh.svg";
import form from "../../assets/about/why-choose-us.svg";

export default function WhyChooseUs({ upsm }) {
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: upsm ? "row" : "column",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <img src={form} alt="" style={{ width: "100%" }} />
        </Box>
        <Box sx={{ p: 6, width: "100%" }}>
          <Typography variant="h4">
            Why Choose{" "}
            <span style={{ color: color.primary, position: "relative" }}>
              Us
              <img
                src={swoosh}
                alt=""
                style={{ position: "absolute", bottom: -10, left: 0 }}
              />
            </span>
          </Typography>
          <ul>
            <li>
              <Typography
                variant="body2"
                color={color.secondary}
                sx={{ my: 2 }}
              >
                Many Years of Experience
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                color={color.secondary}
                sx={{ my: 2 }}
              >
                Effective, Coordinated and Expert services
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                color={color.secondary}
                sx={{ my: 2 }}
              >
                Certified team of trained medical speialists
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                color={color.secondary}
                sx={{ my: 2 }}
              >
                Responsible towards health care industry
              </Typography>
            </li>
            <li>
              <Typography
                variant="body2"
                color={color.secondary}
                sx={{ my: 2 }}
              >
                absolute client satisfaction
              </Typography>
            </li>
          </ul>
        </Box>
      </Box>
    </Container>
  );
}
