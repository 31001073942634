import React from "react";
import About from "./About";
import Header from "../home/Header";
import { useMediaQuery, useTheme } from "@mui/material";
import WhyChooseUs from "./WhyChooseUs";
import WhatWeDo from "./WhatWeDo";
import Footer from "../home/Footer";

export default function About1() {
  const theme = useTheme();
  const upsm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div>
      <Header upsm={upsm} />
      <About upsm={upsm} />
      <WhyChooseUs upsm={upsm} />
      <WhatWeDo upsm={upsm} />
      <Footer upsm={upsm} />
    </div>
  );
}
